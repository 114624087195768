import { render, staticRenderFns } from "./hosting.terraria.vue?vue&type=template&id=efb11fe6&scoped=true&"
var script = {}
import style0 from "./hosting.terraria.vue?vue&type=style&index=0&id=efb11fe6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efb11fe6",
  null
  
)

export default component.exports